import React from "react";
import { Infinity, Clock, Languages } from "lucide-react";

export const ProFeatureList = () => {
  return (
    <>
      <ul className="space-y-3 mb-6">
        <li className="flex items-center">
          <Infinity className="text-orange-500 mr-3 w-6 h-6" />
          <span>Unlimited summaries</span>
        </li>
        <li className="flex items-center">
          <Clock className="text-orange-500 mr-3 w-6 h-6" />
          <span>Any video length</span>
        </li>
        <li className="flex items-center">
          <Languages className="text-orange-500 mr-3 w-6 h-6" />
          <span>Summary translations</span>
        </li>
      </ul>
    </>
  );
};
