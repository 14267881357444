export const languages = [
  { value: "english", label: "English" },
  { value: "arabic", label: "العربية" },
  { value: "bulgarian", label: "Български" },
  { value: "bengali", label: "বাংলা" },
  { value: "chinese_simplified", label: "简体中文" },
  { value: "czech", label: "Čeština" },
  { value: "danish", label: "Dansk" },
  { value: "german", label: "Deutsch" },
  { value: "estonian", label: "Eesti" },
  { value: "greek", label: "Ελληνικά" },
  { value: "spanish", label: "Español" },
  { value: "finnish", label: "Suomi" },
  { value: "french", label: "Français" },
  { value: "hebrew", label: "עברית" },
  { value: "hindi", label: "हिन्दी" },
  { value: "hungarian", label: "Magyar" },
  { value: "indonesian", label: "Indonesia" },
  { value: "italian", label: "Italiano" },
  { value: "japanese", label: "日本語" },
  { value: "korean", label: "한국어" },
  { value: "lithuanian", label: "Lietuvių" },
  { value: "latvian", label: "Latviešu" },
  { value: "malay", label: "Bahasa Melayu" },
  { value: "dutch", label: "Nederlands" },
  { value: "norwegian", label: "Norsk" },
  { value: "polish", label: "Polski" },
  { value: "portuguese_brazil", label: "Português (Brasil)" },
  { value: "portuguese_portugal", label: "Português (Portugal)" },
  { value: "romanian", label: "Română" },
  { value: "russian", label: "Русский" },
  { value: "slovak", label: "Slovenčina" },
  { value: "slovenian", label: "Slovenščina" },
  { value: "swedish", label: "Svenska" },
  { value: "thai", label: "ไทย" },
  { value: "turkish", label: "Türkçe" },
  { value: "filipino", label: "Filipino" },
  { value: "chinese_traditional", label: "繁體中文" },
  { value: "ukrainian", label: "Українська" },
  { value: "urdu", label: "اردو" },
  { value: "vietnamese", label: "Tiếng Việt" },
  { value: "zulu", label: "isiZulu" },
];
