import React, { useState, useEffect, useRef, useCallback } from "react";
import { initializeApp } from "firebase/app";
import {
  getAuth,
  GoogleAuthProvider,
  signInWithPopup,
  signOut,
  onAuthStateChanged,
} from "firebase/auth";
import { getDatabase, ref, onValue, set } from "firebase/database";
import { getFunctions, httpsCallable } from "firebase/functions";
import { decode } from "he";
import "./index.css";
import {
  FileText,
  Settings2,
  Mail,
  LogOut,
  Copy,
  Check,
  Loader,
  X,
  Infinity,
  Clock,
  Languages,
  ShieldCheck,
  Circle,
  CheckCircle,
  MessageSquare,
} from "lucide-react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  DropdownMenuSeparator,
} from "@radix-ui/react-dropdown-menu";
import { ReactComponent as MagicIcon } from "./assets/magic.svg";
import "@fontsource/inter/400.css";
import "@fontsource/inter/500.css";
import "@fontsource/inter/700.css";
import { CustomSelect } from "./customSelect";
import { languages } from "./data";
import { ProFeatureList } from "./components";

const details = {
  email: "mailto:support@skipwatch.ai?subject=Subscription",
  manage_subscription: "#",
};

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const database = getDatabase(app);
const functions = getFunctions(app);

function App() {
  const [user, setUser] = useState({
    name: "John Doe",
    avatar: "/api/placeholder/24/24",
  });
  const [loading, setLoading] = useState(true);
  const [videoId, setVideoId] = useState(null);
  const [subtitleURL, setSubtitleURL] = useState(null);
  const [AIResponse, setAIResponse] = useState(null);
  const [activeTab, setActiveTab] = useState("summary");
  const [showSettings, setShowSettings] = useState(false);
  const [showCopyConfirmation, setShowCopyConfirmation] = useState(false);
  const [processLoading, setProcessLoading] = useState(false);
  const rootRef = useRef(null);
  const [settings, setSettings] = useState({
    language: "english",
    freeCredits: 0,
  });
  const mainRef = useRef(null);
  const [topOpacity, setTopOpacity] = useState(0);
  const [bottomOpacity, setBottomOpacity] = useState(0);
  const [minMainHeight, setMinMainHeight] = useState(0);
  const [updateHeight, setUpdateHeight] = useState(0);
  const [showPlan, setShowPlan] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState("annual");

  const handleScroll = useCallback(() => {
    if (mainRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = mainRef.current;
      const fadeDistance = 20;

      const hasScrollTop = scrollTop > 0;
      const newTopOpacity = hasScrollTop
        ? Math.min(scrollTop / fadeDistance, 1)
        : 0;
      setTopOpacity(newTopOpacity);

      const hasScrollBottom = scrollHeight > clientHeight + scrollTop;
      const distanceFromBottom = scrollHeight - (scrollTop + clientHeight);
      const newBottomOpacity = hasScrollBottom
        ? distanceFromBottom / fadeDistance
        : 0;

      setBottomOpacity(newBottomOpacity);
    }
  }, []);

  useEffect(() => {
    const mainElement = mainRef.current;
    if (mainElement) {
      mainElement.addEventListener("scroll", handleScroll);
      handleScroll();
    }

    return () => {
      if (mainElement) {
        mainElement.removeEventListener("scroll", handleScroll);
      }
    };
  }, [handleScroll, mainRef.current]);

  useEffect(() => {
    setAIResponse(null);
  }, [videoId]);

  const sendMessageToParent = (message) => {
    if (window.parent) {
      window.parent.postMessage(message, "*");
    } else {
      console.error("send message to parent error");
    }
  };

  useEffect(() => {
    function handleMessage(event) {
      const { type, value } = event.data;
      if (type === "SKIPWATCH_SUBTITLE_URL") {
        const finalURL = value.split(
          "https://www.youtube.com/api/timedtext"
        )[1];
        setSubtitleURL(finalURL);
      } else if (type === "SKIPWATCH_VIDEO_ID") {
        setVideoId(value);
      }
    }

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  useEffect(() => {
    if (rootRef.current) {
      const height = rootRef.current.offsetHeight;
      sendMessageToParent({
        type: "SKIPWATCH_IFRAME_HEIGHT",
        value: height,
      });
    }
  }, [
    activeTab,
    showSettings,
    AIResponse,
    minMainHeight,
    updateHeight,
    showPlan,
  ]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
      if (user) {
        const userRef = ref(database, `users/${user.uid}`);
        onValue(userRef, (snapshot) => {
          const data = snapshot.val();
          setSettings((prevSettings) => ({
            ...prevSettings,
            language: data?.settings?.language || "english",
            freeCredits: data?.settings?.freeCredits || 0,
          }));
        });
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const handleSettingChange = (key, value) => {
    setSettings((prevSettings) => ({
      ...prevSettings,
      [key]: value,
    }));
    if (user) {
      set(ref(database, `users/${user.uid}/settings/${key}`), value);
    }
  };

  const handleCopy = () => {
    if (AIResponse) {
      const summaryText = AIResponse.insights
        .map(
          (item) =>
            `${item.topic}\n${item.insights
              .map((insight) => `${insight.emoji} ${decode(insight.insight)}`)
              .join("\n")}`
        )
        .join("\n\n");
      navigator.clipboard.writeText(summaryText);
      setShowCopyConfirmation(true);
      setTimeout(() => setShowCopyConfirmation(false), 5000);
    }
  };

  const signIn = async () => {
    const provider = new GoogleAuthProvider();
    try {
      await signInWithPopup(auth, provider);
    } catch (error) {
      console.error("Login error:", error);
    }
  };

  const handleSignOut = async () => {
    try {
      await signOut(auth);
    } catch (error) {
      console.error("Çıkış hatası:", error);
    }
  };

  const makeOpenAIRequests = async () => {
    if (!user) {
      alert("Bu işlemi gerçekleştirmek için giriş yapmalısınız.");
      return;
    }

    if (settings.freeCredits <= 0) {
      alert("Üzgünüz, kullanılabilir krediniz kalmadı.");
      return;
    }

    if (!subtitleURL) {
      alert("subtitleURL henüz hazır değil.");
      return;
    }

    if (!videoId) {
      alert("videoId yok");
      return;
    }

    setProcessLoading(true);
    const makeOpenAIRequest = httpsCallable(functions, "makeOpenAIRequest");

    try {
      const result = await makeOpenAIRequest({
        subtitleURL: subtitleURL,
        videoId: videoId,
      });

      if (result.data) {
        setAIResponse(result.data.summary);
        alert(`İstek tamamlandı! Kalan hak: ${result.data.remainingCredits}`);
      } else {
        console.error("Unexpected result structure:", result);
        alert("İstek tamamlandı, ancak beklenmeyen bir yanıt alındı.");
      }
    } catch (error) {
      console.error("OpenAI isteği hatası:", error);
      if (error.details) {
        console.error("Error details:", error.details);
      }
      alert(`Hata: ${error.message}`);
    } finally {
      setProcessLoading(false);
    }
  };

  const renderText = (text) => {
    const parts = text.split(/(\*\*.*?\*\*)/);
    return parts.map((part, index) => {
      if (part.startsWith("**") && part.endsWith("**")) {
        return (
          <span key={index} style={{ backgroundColor: "rgba(255,109,36,.24)" }}>
            {part.slice(2, -2)}
          </span>
        );
      }
      return part;
    });
  };

  if (loading) {
    return <div>Yükleniyor...</div>;
  }

  const isSummarizeDisabled = settings.freeCredits <= 0 || processLoading;

  return (
    <div ref={rootRef}>
      {showPlan === true ? (
        <>
          {1 === 0 ? (
            <div className="bg-white p-4">
              <div className="flex justify-between items-center mb-4">
                <img
                  src="https://frontend.eightify.app/logo-pro.a97d97c32255d3dbdc2ea526c20f374b.svg"
                  alt="Eightify Pro"
                  className="h-8"
                />
                <button className="p-1 rounded hover:bg-gray-200">
                  <X
                    className="text-gray-500 cursor-pointer"
                    onClick={() => {
                      setShowPlan(false);
                    }}
                  />
                </button>
              </div>

              <h3 className="text-2xl font-bold mb-4">Take all from it</h3>
              <ProFeatureList />

              <div className="bg-gray-100 p-2 rounded-lg space-y-2 mb-6">
                <div
                  className={`p-3 rounded-lg flex items-center justify-between cursor-pointer transition-all ${
                    selectedPlan === "annual"
                      ? "bg-white shadow"
                      : "bg-transparent"
                  }`}
                  onClick={() => setSelectedPlan("annual")}
                >
                  <div>
                    <p className="font-semibold text-xl">3-day free trial</p>
                    <p className="text-sm text-gray-600">
                      Then only $4.99/mo, billed annually
                    </p>
                  </div>
                  {selectedPlan === "annual" ? (
                    <CheckCircle className="text-orange-500 bg-white rounded-full" />
                  ) : (
                    <Circle className="text-gray-400" />
                  )}
                </div>
                <div
                  className={`p-3 rounded-lg flex items-center justify-between cursor-pointer transition-all ${
                    selectedPlan === "monthly"
                      ? "bg-white shadow"
                      : "bg-transparent"
                  }`}
                  onClick={() => setSelectedPlan("monthly")}
                >
                  <div>
                    <p className="font-semibold text-xl">Monthly, $9.99/mo</p>
                  </div>
                  {selectedPlan === "monthly" ? (
                    <CheckCircle className="text-orange-500 bg-white rounded-full" />
                  ) : (
                    <Circle className="text-gray-400" />
                  )}
                </div>
              </div>

              <button className="w-full bg-orange-500 text-white py-2 rounded-lg font-semibold hover:bg-orange-600 transition-colors">
                {selectedPlan === "annual" ? "Start My Free Trial" : "Pay Now"}
              </button>

              <p className="text-sm text-gray-600 mt-4 flex items-center gap-2">
                <ShieldCheck className="text-green-500 w-9 h-9" />
                If you're not satisfied, we'll provide a refund without
                question.
              </p>
            </div>
          ) : (
            <div className="bg-white p-4">
              <div className="flex justify-between items-center mb-4">
                <img
                  src="https://frontend.eightify.app/logo-pro.a97d97c32255d3dbdc2ea526c20f374b.svg"
                  alt="Eightify Pro"
                  className="h-8"
                />
                <button className="p-1 rounded hover:bg-gray-200">
                  {" "}
                  <X
                    className="text-gray-500 cursor-pointer"
                    onClick={() => {
                      setShowPlan(false);
                    }}
                  />
                </button>
              </div>

              <h2 className="text-2xl font-bold mb-1">
                Monthly plan is active
              </h2>
              <p className="text-gray-600 mb-4">Will expire on Oct 8, 2024</p>
              <ProFeatureList />

              <div className="text-sm text-gray-500">
                <a href={details.email} className="hover:underline">
                  Contact support
                </a>{" "}
                •{" "}
                <a
                  href={details.manage_subscription}
                  target="_blank"
                  className="hover:underline"
                >
                  Manage subscription
                </a>
              </div>
            </div>
          )}
        </>
      ) : user ? (
        <div className="flex flex-col overflow-y-auto overflow-x-hidden">
          <header className="select-none">
            <div className="mx-auto px-4 py-3">
              <div className="flex justify-between items-center">
                <div className="flex space-x-2">
                  <button
                    className={`cursor-default flex items-center px-3 py-2 rounded-md text-sm font-medium ${
                      activeTab === "summary"
                        ? "bg-gray-200 text-gray-900"
                        : "text-gray-500 hover:text-gray-700"
                    }`}
                    onClick={() => {
                      setActiveTab("summary");
                    }}
                  >
                    <FileText className="mr-2 h-4 w-4" />
                    Summary
                  </button>
                </div>

                <div className="flex items-center space-x-2">
                  <div className="flex text-gray-600 space-x-1 transition-colors duration-300">
                    <button
                      disabled={AIResponse === null}
                      onClick={handleCopy}
                      className={`p-1.5 rounded ${
                        AIResponse === null
                          ? "opacity-30"
                          : showCopyConfirmation
                          ? "bg-green-100 hover:bg-green-200"
                          : "hover:bg-gray-200"
                      }`}
                    >
                      {showCopyConfirmation ? (
                        <Check className="h-4 w-4 text-green-600" />
                      ) : (
                        <Copy className="h-4 w-4" />
                      )}
                    </button>
                    <button
                      onClick={() => setShowSettings(!showSettings)}
                      className={`p-1.5 rounded ${
                        showSettings ? "bg-gray-200" : "hover:bg-gray-200"
                      }`}
                    >
                      {showSettings ? (
                        <X className="h-4 w-4" />
                      ) : (
                        <Settings2 className="h-4 w-4" />
                      )}
                    </button>
                  </div>
                  <DropdownMenu
                    onOpenChange={(events) => {
                      if (events) {
                        setMinMainHeight(120);
                      } else {
                        setMinMainHeight(0);
                      }
                    }}
                  >
                    <DropdownMenuTrigger>
                      <img
                        src={user.photoURL}
                        alt={user.name}
                        className="w-6 h-6 rounded-full cursor-pointer"
                      />
                    </DropdownMenuTrigger>
                    <DropdownMenuContent
                      className="min-w-[180px] bg-white rounded-md border border-gray-300 shadow-lg py-1 mt-1 z-50"
                      sideOffset={4}
                      align="end"
                    >
                      <DropdownMenuItem
                        className="px-3 py-1 hover:bg-gray-100 cursor-pointer flex items-center outline-none"
                        onClick={() => {
                          setShowPlan(true);
                        }}
                      >
                        <Mail className="mr-2 h-4 w-4" />
                        <span>My Plan</span>
                      </DropdownMenuItem>
                      <DropdownMenuItem
                        onClick={() => {
                          window.location.href = details.email;
                        }}
                        className="px-3 py-1 hover:bg-gray-100 cursor-pointer flex items-center outline-none"
                      >
                        <Mail className="mr-2 h-4 w-4" />
                        <span>Support</span>
                      </DropdownMenuItem>
                      <DropdownMenuSeparator className="my-1 border-t border-gray-200" />
                      <DropdownMenuItem
                        onClick={handleSignOut}
                        className="px-3 py-1 hover:bg-red-100 cursor-pointer flex items-center outline-none text-red-600"
                      >
                        <LogOut className="mr-2 h-4 w-4" />
                        <span>Sign out</span>
                      </DropdownMenuItem>
                    </DropdownMenuContent>
                  </DropdownMenu>
                </div>
              </div>
            </div>
          </header>

          <div className="relative flex-1">
            <div
              className="content-gradient-top"
              style={{ opacity: topOpacity }}
            />
            <main
              ref={mainRef}
              className="flex-1 px-4 overflow-auto pb-4"
              style={{
                maxHeight: "500px",
                minHeight: minMainHeight,
              }}
            >
              {showSettings ? (
                <div>
                  <h3 className="text-lg font-bold mb-2">Settings</h3>
                  <div>
                    <label
                      htmlFor="language-select"
                      className="block text-sm font-medium text-gray-700 mb-1"
                    >
                      Language
                    </label>
                    <CustomSelect
                      options={languages}
                      value={settings.language}
                      onToggle={() => {
                        setUpdateHeight(Math.random());
                      }}
                      onChange={(value) => {
                        handleSettingChange("language", value);
                      }}
                    />
                  </div>
                </div>
              ) : activeTab === "summary" ? (
                <>
                  {AIResponse
                    ? AIResponse.insights.map((item, index) => (
                        <div key={index}>
                          <h3 className="text-lg font-bold mt-2 mb-2">
                            {item.topic}
                          </h3>
                          <div>
                            {item.insights.map((insight, insightIndex) => (
                              <div
                                key={insightIndex}
                                className="mt-2 mb-2 flex gap-2"
                              >
                                <span>{insight.emoji}</span>
                                <p>{renderText(decode(insight.insight))}</p>
                              </div>
                            ))}
                          </div>
                        </div>
                      ))
                    : null}
                </>
              ) : null}
            </main>
            <div
              className="content-gradient-bottom"
              style={{ opacity: bottomOpacity }}
            />
          </div>

          {!showSettings && AIResponse === null && (
            <footer className="flex-1 px-4">
              {activeTab === "summary" && (
                <button
                  onClick={makeOpenAIRequests}
                  disabled={isSummarizeDisabled}
                  className={`
        flex items-center justify-center px-4 py-2 
        w-full rounded-md text-white font-bold
        transition duration-150 ease-in-out 
        mb-3
        ${
          isSummarizeDisabled
            ? "bg-gray-400"
            : "bg-gradient-to-r from-orange-500 to-orange-600 hover:from-orange-400 hover:to-orange-500"
        }
        focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-opacity-50
      `}
                >
                  {processLoading ? (
                    <>
                      <Loader className="animate-spin mr-2 w-4 h-4" />
                      Summarizing Video...
                    </>
                  ) : (
                    <>
                      <MagicIcon className="mr-2" /> Summarize Video
                    </>
                  )}
                </button>
              )}
            </footer>
          )}
        </div>
      ) : (
        <div className="flex items-center justify-center bg-gray-100">
          <button
            onClick={signIn}
            className="px-4 py-2 font-bold text-white bg-blue-500 rounded-md hover:bg-blue-600 focus:outline-none focus:shadow-outline"
          >
            Sign in with Google
          </button>
        </div>
      )}
    </div>
  );
}

export default App;
