import React, { useState, useEffect, useRef } from "react";
import { Check, ChevronDown } from "lucide-react";

export const CustomSelect = ({ options = [], onToggle, value, onChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [highlightedIndex, setHighlightedIndex] = useState(-1);
  const dropdownRef = useRef(null);
  const buttonRef = useRef(null);
  const optionRefs = useRef([]);

  const toggleSelect = () => setIsOpen(!isOpen);

  useEffect(() => {
    if (typeof onToggle === "function") {
      onToggle();
    }
  }, [isOpen]);

  const handleOptionClick = (option) => {
    onChange(option.value);

    setIsOpen(false);
    buttonRef.current.focus();
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleKeyDown = (event) => {
    switch (event.key) {
      case "Escape":
        setIsOpen(false);
        break;
      case "ArrowDown":
        event.preventDefault();
        if (!isOpen) {
          setIsOpen(true);
        } else {
          setHighlightedIndex((prevIndex) =>
            prevIndex < options.length - 1 ? prevIndex + 1 : prevIndex
          );
        }
        break;
      case "ArrowUp":
        event.preventDefault();
        setHighlightedIndex((prevIndex) =>
          prevIndex > 0 ? prevIndex - 1 : prevIndex
        );
        break;
      case "Enter":
      case " ":
        if (isOpen && highlightedIndex !== -1) {
          handleOptionClick(options[highlightedIndex]);
        } else {
          setIsOpen(true);
        }
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (isOpen && highlightedIndex !== -1) {
      optionRefs.current[highlightedIndex]?.focus();
    }
  }, [isOpen, highlightedIndex]);

  return (
    <div
      className={`relative flex flex-col items-start ${
        isOpen ? "h-[300px]" : "h-auto"
      }`}
      ref={dropdownRef}
    >
      <button
        ref={buttonRef}
        onClick={toggleSelect}
        onKeyDown={handleKeyDown}
        aria-haspopup="listbox"
        aria-expanded={isOpen}
        aria-labelledby="selectButton"
        className="bg-white px-2 py-1 rounded border border-gray-300 flex items-center justify-between gap-1"
      >
        <span id="selectButton" className="sr-only">
          Select
        </span>
        <span>
          {value ? options.find((x) => x.value === value).label : null}
        </span>
        <ChevronDown
          className={`w-4 h-4 ${isOpen ? "transform rotate-180" : ""}`}
        />
      </button>
      {isOpen && (
        <ul
          role="listbox"
          aria-labelledby="selectButton"
          className={`py-1 mt-1 bg-white border border-gray-300 rounded shadow-lg overflow-auto`}
        >
          {options.map((option, index) => (
            <li
              key={option.value}
              ref={(el) => (optionRefs.current[index] = el)}
              role="option"
              aria-selected={value && value === option.value}
              onClick={() => handleOptionClick(option)}
              onKeyDown={(e) => {
                if (e.key === "Enter" || e.key === " ") {
                  handleOptionClick(option);
                }
              }}
              tabIndex="0"
              className={`px-3 py-1 cursor-pointer flex items-center justify-between hover:bg-gray-100`}
            >
              <span>{option.label}</span>
              {value && value === option.value && <Check className="w-4 h-4" />}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
